<template>
  <div>
    <Dialog :show="showDlg" paddingleft="0" paddingright="0" width="859px" height="auto" @close="close">
      <div slot="title">
        <div class="video-title">
          <div>
            <b style="font-weight:400" v-if="!getCustomerVideoViewdata.isView">看视频返<label
                style="color: #E5432E;;font-weight:400">{{ getCustomerVideoViewdata.amount }}元</label>红包</b>
            <b style="font-weight:400" v-if="getCustomerVideoViewdata.isView"><label
                style="color: #E5432E;;font-weight:400">已返{{ getCustomerVideoViewdata.amount }}元红包</label></b>
          </div>
          <div class="zt">
            <label class="lb1" :class="{ 'yk': getCustomerVideoViewdata.isView }">
              {{ getCustomerVideoViewdata.isView ? "已看" : "未看" }}
            </label>
            <label class="lb2" :class="{ 'yk': getCustomerVideoViewdata.isView }"></label>
          </div>
        </div>
      </div>
      <div style="position: relative">
        <video ref="my_videos" :src="url" @timeupdate="timeupdate" @play="controlsPlayVideo" @ended="ended" controls autoplay class="videos"></video>

        <div class="video-footer">
          <div class="video-footer-left">
            已观看 <span>{{ getCustomerVideoViewdata.viewCount }}</span> 人
            限 <span>{{ getCustomerVideoViewdata.maxViewCount }}</span> 人观看
          </div>
<!--          <div class="video-footer-right" @click="$router.push({ name: 'v2-video' })">-->
<!--            视频红包专区-->
<!--          </div>-->
        </div>
        <div class="video-img" v-if="isstate">
          <div class="close" @click.stop="isstate = false">
            <label class="iconfont icon-close"/>
          </div>
          <div class="money">
            {{ getCustomerVideoViewdata.amount.toFixed(2) }}<label style="font-size:12px">元</label>
          </div>
          <div class="btn" @click.stop="isstate = false"></div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Dialog from '../v2-dialog/index.vue'
export default {
  data() {
    return {
      showDlg: false,
      isstate: false,
      getCustomerVideoViewdata: {amount:0},
      playVideo: {
        watchMaxTime: 0, //当前可快进到的最大时长	单位为s
        copyCurrentTime: 0 //下次进入时继续播放的时长  单位为s
      }
    };
  },
  components:{Dialog},
  props: {
    url: String,
    id: Number,
    show: { type: Boolean }
  },
  watch: {
    show(newV) {
      this.showDlg = newV;
      if(newV){
        this.getCustomerVideoView();
      }
    }
  },
  created() {
    this.$nextTick(() => {
      if (!this.videoId) {
        return;
      }
    });
  },
  methods: {
    ...mapActions(["getRedPacketData"]),
    close() {
      this.$emit("close")
    },
    timeupdate(){
      let that = this;
      let myVideo = this.$refs.my_videos; //当前播放器
      if (myVideo.currentTime > that.playVideo.watchMaxTime) {
        //但如果相差1秒就证明是往后拖时间了 正常来说是每次时长更新相差0.3s
        let num = parseFloat(myVideo.currentTime) - parseFloat(that.playVideo.watchMaxTime);
        if (num < 1) {
          //正常播放时，记录当前播放时间
          that.playVideo.watchMaxTime = myVideo.currentTime;
          that.playVideo.copyCurrentTime = myVideo.currentTime;
        } else {
          // 差值大于1s时，视为拖动，将上一次记录的播放时间赋值给当前播放器的播放时间
          myVideo.currentTime = that.playVideo.watchMaxTime;
          that.playVideo.copyCurrentTime = that.playVideo.watchMaxTime;
        }
      }
    },
    controlsPlayVideo() {
      console.log("播放中")
      this.operateDetail(this.id, 2);
    },
    ended() {
      console.log("播放结束");
      this.operateDetail(this.getCustomerVideoViewdata.videoId, 1, 0, () => {
        this.getReceive();
      });
    },
    // 记录用户行为
    async operateDetail(videoId, type, langTime, callback) {
      let data = {
        operateMode: type,  // 1是领红包，2是看视频，3是浏览，4是点赞，5是分享
        videoRewardId: videoId
      };

      if (type === 1) {
        data.playDuration = langTime;
      }

      await this.$getHttpClient().post("/pjyy-deepexi-market-center/api/v1/up/mobile/operate/detail", data,
        {headers: { TokenType: "customer" }}
      );
      // if (type == 1) this.close()
      callback && callback()
    },
    //领取红包
    async getReceive() {
      // 已领取红包或人数已达上限则不调用领红包逻辑
      if (this.getCustomerVideoViewdata.viewCount >= this.getCustomerVideoViewdata.maxViewCount) {
        return;
      }
      // 判断本次页面中是否已经领取过该视频， 避免重复提交
      if (this.getCustomerVideoViewdata.isView) {
        return;
      }
      let url = "/pjyy-deepexi-task/api/v1/money/addV2";
      let res = await this.$getHttpClient().post(url, {
        businessId: this.getCustomerVideoViewdata.videoId,
        direction: 1, //资金方向【1收入-1支出】 不能为空
        channel: 2, //资金途径【1拼团返现2视频红包】不能为空
        setupNumber: 0, //阶梯序号
        unitPrice: 0, //返现单价
        totalNumber: 0, //总数量
        totalAmount: 0.0, //本次红包总金额 不能为空
        duration: 0
      });
      if (res.data.code == 200) {
        this.getRedPacketData();
        this.getCustomerVideoViewdata.isView=true;
        this.isstate = true;
      }
    },

    async getCustomerVideoView() {
      let url = `/pjyy-deepexi-market-center/api/v1/up/mobile/video/getCustomerVideoView?videoId=${this.id}`;
      let res = await this.$getHttpClient().get(url, {
        headers: { TokenType: "customer" }
      });
      if (res.data.code === "200") {
        this.getCustomerVideoViewdata = res.data.data;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.video-img {
  text-align: center;
  font-size: 28px;
  font-family: PangMenZhengDao;
  font-weight: 400;
  font-style: italic;
  color: #F1D3A7;
  position: absolute;
  bottom: 30%;
  left: 30%;
  width: 270px;
  height: 281px;
  background-size: cover;
  background-image: url("https://obs.pujian.com/frontend/app/other/20230421175003.png");

  .close {
    position: absolute;
    right: -20px;
    top: -20px;
    color: #ece9e9;
    font-weight: 600;
    cursor: pointer;

    .icon-close {
      font-size: 24px;
    }
  }

  .money {
    position: absolute;
    top: 110px;
    left: 35px;
    font-weight: bold;
    color: #FFFFFF;
    height: 32px;
    line-height: 32px;
    font-size: 30px;
    font-family: Source Han Sans CN;
  }

  img {
    width: 270px;
    height: 281rpx;
  }
}

.btn {
  position: absolute;
  bottom: 28px;
  left: 30px;
  width: 210px;
  height: 44px;
}

.video-title {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: left;
  display: flex;

  .zt {
    color: #E5432E;
    position: relative;
    padding: 0 2px;
    margin-left: 10px;

    .lb1 {
      font-weight: 400;

      &.yk {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .lb2 {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 36px;
      height: 4px;
      background: #E5432E;
      border-radius: 4px 4px 4px 4px;
      opacity: 0.1;

      &.yk {
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.videos {
  width: 859px;
  margin-bottom: 5px;
}

.video-footer {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;

  .video-footer-left {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;

    span {
      color: #e5432e;
    }
  }

  .video-footer-right {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3875f6;
    border-bottom: 1px solid #3875f6;
  }
}
</style>
