import _objectSpread from "/var/jenkins_home/workspace/frontend-web-shop/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import { mapGetters } from 'vuex';
var rebate = {
  computed: _objectSpread({}, mapGetters({
    cardInfo: 'cardInfo'
  })),
  methods: {
    //享受下一级返利
    proportionFilter_1: function proportionFilter_1() {
      var value = this.cardInfo.proportion;
      var res = 0;
      var current;
      if (this.cardInfo.isMax == '0') {
        current = Number(value);
      } else {
        current = value;
      }
      switch (Math.ceil(Number(current) / 10)) {
        case 3:
          res = 3;
          break;
        case 4:
          res = 4;
          break;
        case 5:
          res = 5;
          break;
        case 6:
          res = 6;
          break;
        case 7:
          res = 7;
          break;
      }
      return res;
    }
  }
};
export { rebate };