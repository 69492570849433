export default {
  data() {
    return {
      dl_modal: false,
      dl_modal_obj: {},
    }
  },

  filters: {
    replaceStr(str) {
      if (!str) {
        return ""
      }
      let arr = str.split("-")
      return arr[0]
    },
    replaceStr1(str) {
      if (!str) {
        return ""
      }
      let arr = str.split("-")
      return arr[1]
    },
    // 设置活动标签的名称
    setActivityTagActiveName(val) {
      if (!val) return '';
      if (val == 1) return '立减';
      if (val == 2) return '红包';
      if (val == 4) return '赠送';
    },
    // 设置活动标签的背景色
    setActivityTagBgColor(val) {
      if (!val) return '';
      if (val == 1 || val == 3) return '#FFF3F3';
      if (val == 2) return '#FFF5D6';
      if (val == 5) return '#e4faff';
    },
    setTypeName(type) {
      if (!type) return '';
      if (type == 1) return '减';
      if (type == 2) return '返';
      if (type == 4) return '送';
    }
  },

  methods: {
    // 让利类型  (1.票折，2.红包，3.特价, 4.赠品)
    setActiveNameBg(type) {
      if (!type) return '';
      if (type == 1) return 'linear-gradient(0deg, #fe4b4f 0%, #fea8c1 100%)';
      if (type == 2) return 'linear-gradient(0deg, #fc664c 0%, #fcb466 100%)';
      if (type == 4) return 'linear-gradient(180deg, #a8ecfe 0%, #2690ed 100%)';
    },

    setActiveTagBgColor(type) {
      if (!type) return '';
      if (type == 1) return '#fff3f3';
      if (type == 2) return '#fff5d6';
      if (type == 4) return '#e4faff';
    },

    judgmentPreferentialType() {
      if (this.product.activityVo && this.product.activityVo.activityStrategyReduceVOS && this.product.activityVo.activityStrategyReduceVOS.length) {
        // preferentialType 让利类型  (1.票折，2.红包，3.特价, 4.赠品)
        for (let i = 0; i < this.product.activityVo.activityStrategyReduceVOS.length; i++) {
          let item = this.product.activityVo.activityStrategyReduceVOS[i];
          if (item.preferentialType == 1) {
            return true;
          }
        }
      }
      return false;
    },

    // 查看代理联系方式
    lookPhone(item) {
      // 代理组件弹窗使用下面三个字段，新接口没有下面三个字段，所以需要把新字段赋值给组件使用的字段
      if (!item.controlFactoryContacts) {
        item.controlFactoryContacts = item.factoryControlContactName;
      }
      if (!item.controlFactoryPhone) {
        item.controlFactoryPhone = item.factoryControlContactPhone;
      }
      if (!item.controlRule) {
        item.controlRule = item.factoryControlRule;
      }
      this.dl_modal_obj = item;
      this.dl_modal = true;
    },

    popStatus(e){
      this.dl_modal = e
    },
  }
}
