import "core-js/modules/es.number.constructor.js";
export default {
  name: "lowestPriceForSimilar",
  components: {},
  data: function data() {
    return {};
  },
  props: {
    product: {
      type: Object,
      default: function _default() {}
    },
    //是否相似商品显示 ，相似商品不显示购物车动画，不显示相识商品操作
    isShowSimilar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    //显示相识商品
    showSimilar: function showSimilar() {
      this.$emit('showSimilar');
    },
    /**
     * @description: 判断当前商品是否是同类商品中的最低价格
     * @param {*} item
     * @param theCurrentItemIsTheLowestPriceInItsClass  当前商品的价格是同类商品中的最低价格
     */
    lowestPriceForSimilarItems: function lowestPriceForSimilarItems(item, theCurrentItemIsTheLowestPriceInItsClass) {
      // 特价商品时，特价的价格>同类商品的最低价
      if (item.isActivityGood && item.activityGoodInfo.activityPrice > item.similarGoodsLowestPrice) return true;

      // 商品有活动时，商品的活动价格>同类商品的最低价
      if (item.activityVo.activityStrategyReduceVOS.length && item.activityVo.activityStrategyReduceVOS[0].activityPrice > item.similarGoodsLowestPrice && item.activityVo.activityStrategyReduceVOS[0].preferentialType != 4) return true;

      // 没有活动时，商品原价>同类商品的最低价
      if (item.similarGoodsLowestPrice && Number(item.unitPrice) > item.similarGoodsLowestPrice) return true;

      // 当前商品的价格是同类商品中的最低价格
      if (theCurrentItemIsTheLowestPriceInItsClass) {
        if (item.similarGoodsActivityNum) return true;
      }
      return false;
    }
  }
};