<template>
  <div>
    <!--商品列表的价格-->
    <div class="price" v-if="productType === 'productList'">
      <span v-if="!product.unitPrice">定价中</span>

      <template v-else-if="product.isActivityGood">
        <span><span class="tj-bg">特价</span>￥{{ product.activityGoodInfo.activityPrice }}</span>
        <span class="tj-des" v-if="product.activityGoodInfo && product.activityGoodInfo.activityCount">
          {{ product.activityGoodInfo.activityCount }}{{ product.sellUnit }}起购
        </span>
        <span class="del">￥{{ product.unitPrice }}</span>
      </template>

      <template v-else-if="judgmentPreferentialType()">
        <span><span class="tj-bg">特价</span>￥{{ product.activityVo.activityStrategyReduceVOS[0].activityPrice }}</span>
        <span class="del">￥{{ product.unitPrice }}</span>
      </template>

      <span v-else>￥{{ product.unitPrice }}/{{ product.sellUnit }}</span>
    </div>

    <!--商品列表hover的价格-->
    <li class="price price-hover" v-else-if="productType === 'productListHover'">
      <p style="margin-left: 0; color: #e5432e;" v-if="!product.unitPrice">定价中</p>
      <template v-else-if="product.isActivityGood">
        <p>特价</p>
        <p>￥{{ product.activityGoodInfo.activityPrice }}/{{ product.sellUnit }}</p>
        <p class="tj-des" v-if="product.activityGoodInfo && product.activityGoodInfo.activityCount">
          {{ product.activityGoodInfo.activityCount }}{{ product.sellUnit }}起购
        </p>
      </template>
      <template v-else-if="judgmentPreferentialType()">
        <p>特价</p>
        <p>￥{{ product.activityVo.activityStrategyReduceVOS[0].activityPrice }}/{{ product.sellUnit }}</p>
      </template>
      <template v-else>
        <p>采购价格</p>
        <p>￥{{ product.unitPrice }}/{{ product.sellUnit }}</p>
      </template>
    </li>
  </div>
</template>

<script>
export default {
  name: "ProductPriceItem",
  components: {},
  data() {
    return {}
  },
  props: {
    product: {
      type: Object,
      default() {
        return {}
      }
    },
    // 类型用于判断，是商品列表还是商品hover状态
    productType: {
      type: String,
      default: ''
    }
  },
  computed: {},
  watch: {},
  mixins: [],
  created() {
  },
  mounted() {
  },
  methods: {
    judgmentPreferentialType() {
      if (this.product.activityVo && this.product.activityVo.activityStrategyReduceVOS && this.product.activityVo.activityStrategyReduceVOS.length) {
        // preferentialType 让利类型  (1.票折，2.红包，3.特价, 4.赠品)
        for (let i = 0; i < this.product.activityVo.activityStrategyReduceVOS.length; i++) {
          let item = this.product.activityVo.activityStrategyReduceVOS[i];
          if (item.preferentialType == 1) {
            return true;
          }
        }
      }
      return false;
    }
  }
}
</script>

<style scoped lang="less">
.price {
  margin-top: 11px;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: #e5432e;
  line-height: 16px;

  .del {
    color: #999999;
    text-decoration: line-through;
  }

  p {
    font-weight: 400;
    width: 85px;

    &:nth-child(2) {
      color: #e5432e;
    }
  }
}

.price-hover {
  display: flex;
  font-size: 14px;
  color: #666;
  margin-bottom: 6px;
}

.tj-bg{
  background: #AE09FF;
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;
  padding: 0 4px;
  margin-right: 4px;
}

.tj-des {
  display: inline-block;
  padding: 1px 4px;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 400;
  background: #FFF3F3;
  color: #E5432E !important;
  text-align: center;
  margin-left: 3px;
  height: fit-content;
  line-height: initial;
}
</style>
