var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.isShowSimilar && _vm.product.similarGoodsNum > 0 ? _c('div', {
    staticClass: "similar",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showSimilar.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "left-c"
  }, [_c('div', {
    staticStyle: {
      "transform": "scale(0.9)",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.product.similarGoodsNum) + "种同类商品")]), _vm.lowestPriceForSimilarItems(_vm.product, true) ? _c('div', {
    staticClass: "di"
  }) : _vm._e(), _vm.lowestPriceForSimilarItems(_vm.product, false) && _vm.product.similarGoodsLowestPrice ? _c('div', {
    staticClass: "min-price"
  }, [_vm._v(" 最低价"), _c('label', {
    staticStyle: {
      "color": "#E5432E"
    }
  }, [_vm._v("￥" + _vm._s(_vm.product.similarGoodsLowestPrice))]), _c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("@/assets-v2/images/down.png")
    }
  })]) : _vm.lowestPriceForSimilarItems(_vm.product, true) ? _c('div', {
    staticClass: "min-price"
  }, [_vm._v(" 有"), _c('label', {
    staticStyle: {
      "color": "#E5432E"
    }
  }, [_vm._v(_vm._s(_vm.product.similarGoodsActivityNum) + "个活动")])]) : _vm._e()]), _vm._m(0)]) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticStyle: {
      "float": "right",
      "font-weight": "600",
      "line-height": "18px"
    }
  }, [_c('i', {
    staticClass: "iconfont icon-arrow-right"
  })]);
}];
export { render, staticRenderFns };