// 埋点接口
var kv = {
  methods: {
    kv_visit: function kv_visit(pageCode, pageSubCode) {
      if (!pageSubCode) {
        pageSubCode = this.$route.query && this.$route.query.activityId;
      }
      if (pageSubCode) {
        pageCode = 1;
      }
      var url = '/pjyy-deepexi-collect-center/api/v3/collect/visit';
      var param = {
        pageCode: pageCode,
        pageSubCode: pageSubCode,
        visitTimestamp: new Date().getTime()
      };
      this.$getHttpClient({
        useSpanner: false
      }).post(url, [param]);
    },
    //添加购物车
    kv_addCard: function kv_addCard(pageCode, productId, pageSubCode) {
      if (!pageSubCode) {
        pageSubCode = this.$route.query && this.$route.query.activityId;
      }
      if (pageSubCode) {
        pageCode = 1;
      }
      var url = '/pjyy-deepexi-collect-center/api/v3/collect/operate';
      var param = {
        pageCode: pageCode,
        type: 1,
        productId: productId,
        pageSubCode: pageSubCode
      };
      this.$getHttpClient({
        useSpanner: false
      }).post(url, param);
    }
  }
};
export { kv };