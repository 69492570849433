<template>
  <div class="labelsBox">
    <span class="labelsBox-item labelsBox-ll" v-if="itemObject.coldChain && itemObject.coldChain === '1'">冷链</span>
    <span class="labelsBox-item labelsBox-mt" v-if="itemObject && (itemObject.isRestrictedUse === 1 || (itemObject.productInfoVO && itemObject.productInfoVO.isRestrictedUse === 1))">门诊统筹</span>
    <span class="labelsBox-item labelsBox-ic" v-if="itemObject.insuranceCode == '1' || itemObject.category == '1'">甲</span>
    <span class="labelsBox-item labelsBox-ic" v-if="itemObject.insuranceCode == '2' || itemObject.category == '2'">乙</span>
    <span class="labelsBox-item labelsBox-ic" v-if="itemObject.insuranceCode == '3' || itemObject.category == '3'">丙</span>
    <span class="labelsBox-item labelsBox-otc" v-if="itemObject && (itemObject.isPrescription === 0 || (itemObject.productInfoVO && itemObject.productInfoVO.isPrescription === 0))">OTC</span>
    <span class="labelsBox-item labelsBox-rx" v-if="itemObject && (itemObject.isPrescription === 1 || (itemObject.productInfoVO && itemObject.productInfoVO.isPrescription === 1))">Rx</span>
    <span class="labelsBox-item labelsBox-jy" v-if="itemObject.isBaseDrug === 1">基药</span>
    <span class="labelsBox-item labelsBox-jc" v-if="itemObject.isCentralizedProcurement === 1">集采</span>
  </div>
</template>

<script>
export default {
  props:{
    itemObject:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data(){
    return{

    }
  }
}
</script>

<style scoped lang="scss">
  .labelsBox{
    text-align: left;
    font-size: 12px;
    .labelsBox-item{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #FFFFFF;
      padding: 0 3px;
      border-radius: 2px;
      margin-right: 4px;
    }
    .labelsBox-mt{
      background: linear-gradient( 90deg, #FD9638 0%, #FC7F2D 100%);
    }
    .labelsBox-ic{
      background: linear-gradient( 90deg, #61B9F9 0%, #4F9BF7 100%);
    }
    .labelsBox-otc{
      background: linear-gradient( 90deg, #0CDB96 0%, #40E593 100%);
    }
    .labelsBox-rx{
      background: linear-gradient( 90deg, #F91570 0%, #F66199 100%);
    }
    .labelsBox-jy{
      background: linear-gradient( 90deg, #36DC89 0%, #14C408 100%);
    }
    .labelsBox-jc{
      background: linear-gradient( 90deg, #FFBA6B 0%, #FF5741 100%);
    }
    .labelsBox-ll{
      background: linear-gradient( 90deg, #5E89FF 0%, #3B70FF 100%);
    }
  }
</style>
