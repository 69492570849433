<template>
    <div class="slot-content" v-if="rulerData">
        <div class="pop_content" style="padding-bottom: 0">
            <div class="section">
                <div class="section_left">
                    <div class="active_logo"></div>
                    <span class="similar_text">代理方式</span>
                </div>
                <div class="section_right">
                    <i class="el-icon-error" @click="closePop" style="font-size: 20px"></i>
                </div>
            </div>
        </div>
        <div class="dl_content">
            <div class="dl_content-des">
                <div class="dl_content-title">代理规则</div>
                <div class="dl_content-des-msg" v-if="rulerData.controlRule != ''">
                    {{ rulerData.controlRule }}
                </div>
                <div class="fp-empty" v-if="!rulerData.controlRule">
                    <img src="https://obs.pujian.com/frontend/app/order/order/empty.png"></img>
                    <div>暂无规则</div>
                </div>
            </div>
            <div class="dl_content-info">
                <div class="dl_content-info-item">联系人：{{ rulerData.controlFactoryContacts }}</div>
                <div class="dl_content-info-item">电&emsp;话：{{ rulerData.controlFactoryPhone }}</div>
            </div>
            <div class="dl_content-btn" @click="gophone(rulerData)">
                <img src="https://obs.pujian.com/frontend/app/other/dl_phone.png"
                        mode="aspectFill">
                <span>立即联系</span>
            </div>
        </div>
    </div>
</template>
<script>
import { AccountStorage } from "@services/account";
const accountStorage = new AccountStorage();
export default {
    props: {
        // 是否禁用输入框
        rulerData: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {}
    },
    methods:{
        closePop(){
            this.$emit("popStatus", false);
        },
        //拨打电话
        async gophone(item) {
            const url = "/pjyy-deepexi-supplier-center/ucAccount/proxyRuleAccessRecords";
            const result = await this.$getHttpClient({},this.supplierToken).post(url, {
                userCode: accountStorage.getCacheUserInfo().account,
                supplierCode: item.supplierCode || "",
                productCode: item.productCode || "",
                productId: item.productId || item.id || ""
            });
            // 创建输入框元素
            let oInput = document.createElement('input');
            // 将想要复制的值
            oInput.value = item.controlFactoryPhone;
            // 页面底部追加输入框
            document.body.appendChild(oInput);
            // 选中输入框
            oInput.select();
            // 执行浏览器复制命令
            document.execCommand('Copy');
            // 弹出复制成功信息
            this.$messageTip("手机号复制成功！",'success');
            // 复制后移除输入框
            oInput.remove();
            this.$emit("popStatus", false);
        },
    }
}
</script>

<style scoped lang="scss">
.pop_content {
  position: relative;
}
.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
}

.section_left {
  display: flex;
  align-items: center;

  .active_logo {
    display: inline-block;
    width: 13px;
    height: 20.5px;
    background-image: url(https://obs.pujian.com/frontend/applet/index/img_adorn.png);
    background-size: contain;
  }

  .similar_text {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #222222;
  }
}

.section_right {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #717171;
}

.close_section {
  display: inline-block;
  position: absolute;
  bottom: calc(0px - var(--window-bottom));
  z-index: 10074;
  background: #EEEEEE;
  display: flex;
  width: 100%;

  .cancel_btn {
    width: 50%;
    height: 55px;
    line-height: 55px;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
  }

  .all {
    color: #3F8EF7;
  }
}

.dl_content {
  padding: 15px;
  border-radius: 20px;
  .dl_content-des {
    background: rgba(56, 117, 246, 0.05);
    border-radius: 8px;

    .dl_content-title {
      width: 95px;
      background: #3875F6;
      color: #ffffff;
      font-size: 14px;
      padding: 5px;
      text-align: center;
      border-radius: 8px 0 29px 0;
    }

    .dl_content-des-msg {
      padding: 12px;
      font-weight: 400;
      color: #717171;
      line-height: 18px;
      font-size: 12px;
      text-indent: 2em;
    }

    // 无数据状态
    .fp-empty {
      width: 100%;
      height: auto;
      text-align: center;

      img {
        width: 88px;
        margin: 0 auto;
        height: 88px;
        margin-bottom: 22px;
      }

      view {
        width: 164px;
        height: 25px;
        font-size: 18px;
        font-weight: 500;
        color: rgb(192, 196, 204);
        font-weight: bold;
        line-height: 25px;
        margin: 0 auto;
        margin-bottom: 8px;
      }
    }
  }

  .dl_content-info {
    background: rgba(56, 117, 246, 0.05);
    border-radius: 8px;
    margin-top: 4px;
    padding: 0 12px;

    .dl_content-info-item {
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 400;
      color: #717171;
      font-size: 12px;
      text-align: left;
    }

    .dl_content-info-item:last-child {
      padding-top: 0;
    }
  }

  .dl_content-btn {
    width: 100%;
    height: 41px;
    background: #3875F6;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 28px;
      height: 28px;
      margin-right: 9px;
    }

    span {
      font-size: 20px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}

</style>
